// @use "@material/card";
// @use "@material/icon-button";
// @import "@material/layout-grid/mdc-layout-grid";

// @include card.core-styles;
// @include icon-button.core-styles;

// @import 'material-design-icons/iconfont/material-icons.css';

button {
  
}
video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
}

#app {
  text-align: center;
}

.box {
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1) !important;
}